

const Footer = () => {
    return (
        <footer className="flex justify-center absolute bottom-5 w-full ">
            <a href="https://fredcode.com.br/" >fredcode.com.br</a>
        </footer>
    )
}

export default Footer
